import { Injectable } from '@angular/core';
import { dbConst } from './databaseConstants';
import { FirestoreCrudService } from './crudService';
import { AuthService } from '../auth.service';
import { collection, collectionData, Firestore, limitToLast, orderBy, query } from '@angular/fire/firestore';
import { IPlaato2 } from 'app/models/plaato2';
import { v4 as uuid } from 'uuid';
import { Observable, pipe, lastValueFrom, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromPlaato2 from 'app/state/plaato2';

@Injectable({
  providedIn: 'root'
})
export class Plaato2Service {
  private crudService: FirestoreCrudService<IPlaato2>;
  constructor(
    private firestore: Firestore,
    private store: Store,
    private authService: AuthService) {
    this.crudService = new FirestoreCrudService<IPlaato2>(firestore, authService, dbConst.plaato2);
  }

  public triggerActive = false;

  public getNewId() {
    return this.crudService.getNewId();
  }

  async add(item: IPlaato2, id: string = "") {
    if (id === "") {
      id = this.crudService.getNewId();
    }
    const newItem = await this.crudService.add(item, id);
    return newItem;
  }

  async update(item: IPlaato2) {
    return await this.crudService.update(item);
  }

  async delete(id: string) {
    if (id)
      await this.crudService.delete(id);
  }

  getItem(id: string) {
    return this.crudService.get(id)
  }

  getItems(barId: string) {
    return this.crudService.getItems(barId);
  }




  // // Our list method will get all the Entities in the Collection
  // async isOnline(): Promise<IPlaato2[]> {

  //   const listRef = collection(this.firestore, dbConst.plaato2);
  //   const q = query(listRef, orderBy('id'), limitToLast(1));

  //   const sub = collectionData(q, { idField: 'id' }) as Observable<IPlaato2[]>;

  //   return await lastValueFrom(sub.pipe(
  //     tap((items: IPlaato2[]) => {
  //       console.log(items);

  //       this.store.dispatch(fromPlaato2.setIsOffline({ isOffline: false }));

  //     })
  //   ));
  // }







  getItemsOnce(barId: string) {
    return this.crudService.getItemsOnce(barId, "name", 100);
  }

  getItemsPromise(barId: string) {
    return this.crudService.getItemsOnce(barId, "name", 100);
  }

  async softUpdate(id: string, item: any, uid: string) {
    return await this.crudService.softUpdate(id, item, uid);
  }

}
