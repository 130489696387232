import { createEntityAdapter, EntityAdapter, EntityState, Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { IKeg, IKegVM } from 'app/models/keg';
import * as KegActions from './actions'
import * as fromUnits from '../units/reducer';
import * as fromBeverage from '../beverage/reducer';
import * as fromLocation from '../location/reducer';
import * as fromRouter from '../router/router-state.selectors';

import { UnitsHelper } from 'app/services/units.helper';
import { IBeverage, IBeverageVM } from 'app/models/beverage';
import { ILocation } from 'app/models/location';


export interface State extends EntityState<IKeg> {
  // additional entity state properties
  selectedId: string | null;
  error: string;
}


export function sortByName(a: IKeg, b: IKeg): number {
  return a.name.localeCompare(b.name);
}
export function selectKegId(a: IKeg): string {
  return a.id ?? '';
}
export const adapter: EntityAdapter<IKeg> = createEntityAdapter<IKeg>({
  sortComparer: sortByName,
  selectId: selectKegId
});

export const initialState: State = adapter.getInitialState({
  selectedId: null,
  error: ''
});

export const stateName = 'keg';

export const reducer = createReducer<State>(
  initialState,
  on(KegActions.clearAllItems, (state) => {
    return adapter.removeAll(state);
  }),
  on(KegActions.loadItemsSuccess, (state, { items }) => {
    return adapter.setAll(items, state);
  }),
  on(KegActions.updateItem, (state, { item }) => {
    return adapter.setOne(item, state);
  }),
  on(KegActions.setItem, (state, action) => Object.assign({ ...state, selectedId: action.id })),
  on(KegActions.setItemFromListPage,
    (state, action) => {
      return Object.assign({
        ...state, selectedId: action.id
      })
    })


);


//export const getSelectedId = (state: KegState) => state.selectedKegId;

// get the selectors
// get the selectors
const {
  selectAll,
  selectEntities: selectItemEntities,
  selectIds: selectItemIds,
  selectTotal
} = adapter.getSelectors();

export const getSelectedId = (state: State) => state.selectedId;
const getState = createFeatureSelector<State>(stateName);
export const selectAllItems = createSelector(getState, selectAll);
export const selectAllEntities = createSelector(getState, selectItemEntities);
export const selectCurrentId = createSelector(getState, getSelectedId);
export const selectCurrentItem = createSelector(selectAllEntities, selectCurrentId, (entities, id) => {
  if (id) return entities[id];
  return null;
});

export const selectEntity = (id: string) => createSelector(selectAllEntities, entities => entities[id]);

export const getKegsWithUnit = createSelector(
  selectAllItems,
  fromUnits.getUnits,
  (kegs, units) => {
    if (units) {
      const helper = new UnitsHelper();
      const retKegs: IKeg[] = [];
      kegs.forEach(item => {
        if (!item) return;
        const retItem: IKeg = {
          ...item,
          size: !!item ? helper.toUnit(item.size, 'l', units.kegs, 2) : 0,
          amount: !!item ? helper.toUnit(item.amount, 'l', units.kegs, 2) : 0,
          nettoWeight: item && item.nettoWeight ? helper.toUnit(item.nettoWeight ?? 4, 'kg', units.kegWeight, -1) : 4,
        };
        retKegs.push(retItem);
      });

      return retKegs;
    }
    return kegs;
  });



export const getKegsWithUnitBeverageAndLocation = createSelector(
  getKegsWithUnit,
  fromBeverage.selectAllEntities,
  fromLocation.selectAllEntities,
  (kegs: IKegVM[], beverages: Dictionary<IBeverage>, locations: Dictionary<ILocation>) => {
    const retKegs: IKegVM[] = [];
    kegs.forEach(item => {
      const retItem: IKegVM = {
        ...item,
        location: item.locationId ? locations[item.locationId] : undefined,
        beverage: item.beverageId ? beverages[item.beverageId] : undefined,
      };
      retKegs.push(retItem);
    });
    return retKegs;
  });

export const getKegsWithUnitFromBeverage = (beverageId: string) => createSelector(
  getKegsWithUnit,
  (kegs) => {
    return kegs.filter(x => x.beverageId === beverageId);
  });

export const getKegWithUnits = (id: string) => createSelector(
  getKegsWithUnit,
  (kegs) => {
    return kegs.find(x => x.id === id);
  });



export const getBeverageWithKegs = (id: string) => createSelector(
  fromBeverage.selectEntity(id),
  getKegsWithUnit,
  (item, kegs): IBeverageVM | undefined => {
    if (item && kegs) {
      const retItem = { ...item };
      retItem.kegs = [];

      kegs.forEach(keg => {
        if (keg.beverageId === id) {
          retItem.kegs?.push(keg);
        }
      });
      return retItem;
    }

    if (item) {
      return item;
    }
    return undefined;
  }
);

export const getBeverageWithKegsFromRouteParams = createSelector(
  fromRouter.selectRouteParams,
  fromBeverage.selectAllEntities,
  getKegsWithUnit,
  ({ id }, items, kegs): IBeverageVM | undefined => {
    const item = items[id];
    if (item && kegs) {
      const retItem = { ...item };
      retItem.kegs = [];

      kegs.forEach(keg => {
        if (keg.beverageId === id) {
          retItem.kegs?.push(keg);
        }
      });
      return retItem;
    }

    if (item) {
      return item;
    }
    return undefined;
  }
);


export const getBeveragesWithKegs = createSelector(
  fromBeverage.selectAllItems,
  getKegsWithUnit,
  (items: IBeverageVM[], kegs: IKeg[]) => {
    return items.map((item) => {
      return {
        ...item,
        kegs: kegs.filter(x => x.beverageId === item.id)
      }
    })
  }
);



export const getKegWithUnitsFromParams = createSelector(
  fromRouter.selectRouteParams,
  getKegsWithUnit,
  ({ id }, kegs) => {
    return kegs.find(x => x.id === id)
  });




export const getFromParams = createSelector(
  fromRouter.selectRouteParams,
  selectAllItems,
  ({ id }, kegs) => {
    return kegs.find(x => x.id === id)
  }
);
