import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { ITap, ITapAmount, ITapVM } from 'app/models/tap';
import * as TapActions from './actions'
import * as fromKegs from '../keg';
import * as fromPlaato from '../plaato';
import * as fromPlaato2 from '../plaato2';
import * as fromCustomKegMon from '../custom_keg_mon';
import * as fromKegtron from '../kegtron';
import { Dictionary, EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { IKeg, IKegVM } from 'app/models/keg';
import { IKegtron, IKegtronVM } from 'app/models/kegtron';
import { ICustomKegMon, ICustomKegMonVM } from 'app/models/customKegMon';
import { IPlaato, IPlaatoVM } from 'app/models/plaato';
import * as fromRouter from '../router/router-state.selectors';
import { IPlaato2, IPlaato2VM } from 'app/models/plaato2';

export interface State extends EntityState<ITap> {
  selectedId: string | null;
  tapsAmounts: ITapAmount[];
  error: string;
}


export function sortByOrder(a: ITap, b: ITap): number {

  const compare = a.order - b.order;
  if (compare > 0) {
    return 1;
  } else if (compare < 0) {
    return -1;
  } else {
    return 0;
  }
}

export function sortBySortOrder(a: ITap, b: ITap): number {
  return a.name.localeCompare(b.name);
}




export function selectId(a: ITap): string {
  return a.id ?? '';
}
export const adapter: EntityAdapter<ITap> = createEntityAdapter<ITap>({
  sortComparer: sortByOrder,
  selectId: selectId
});

export const initialState: State = adapter.getInitialState({
  selectedId: null,
  tapsAmounts: [],
  error: ''
});

export const stateName = 'taps';

export const reducer = createReducer<State>(
  initialState,
  on(TapActions.setItem, (state, action) => Object.assign({ ...state, selectedId: action.id })),
  on(TapActions.clearAllItems, (state) => {
    return adapter.removeAll(state);
  }),
  on(TapActions.loadItemsSuccess, (state, { items }) => {
    return adapter.setAll(items, state);
  }),
  on(TapActions.loadTapsFailure, (state, action): State => {
    return {
      ...state,
      error: action.error
    };
  }),
  on(TapActions.updateTapsOrigAmount, (state, action): State => {

    // update an array element
    const updatedTapAmount = action.tapsAmount;
    const existingTapAmount = state.tapsAmounts.find(x => x.id === updatedTapAmount.id);
    if (!existingTapAmount) {
      return {
        ...state,
        tapsAmounts: [...state.tapsAmounts, updatedTapAmount]
      }
    }

    const newState = state.tapsAmounts.map(task => {
      if (task.id === updatedTapAmount.id) {
        return updatedTapAmount;
      }
      return task;
    });

    return {
      ...state,
      tapsAmounts: newState
    };
  }),

  on(TapActions.softUpdateFailure, (state, action): State => {
    return {
      ...state,
      error: action.error
    };
  }),
  on(TapActions.updateWithKegFailure, (state, action): State => {
    return {
      ...state,
      error: action.error
    };
  }),
);


const {
  selectAll,
  selectEntities,
  selectIds: selectItemIds,
  selectTotal
} = adapter.getSelectors();

export const getSelectedId = (state: State) => state.selectedId;
const getState = createFeatureSelector<State>(stateName);
export const selectAllItems = createSelector(getState, selectAll);
export const selectAllEntities = createSelector(getState, selectEntities);
export const selectCurrentId = createSelector(getState, getSelectedId);
export const selectCurrentItem = createSelector(selectAllEntities, selectCurrentId, (entities, id) => {
  if (id) return entities[id];
  return null;
});

export const selectEntity = (id: string) => createSelector(selectAllEntities, entities => entities[id]);

export const selectError = createSelector(getState, state => state.error);

// const getTapFeatureState = createFeatureSelector<TapState>(stateName);

// //Selectors
// export const getAllItems = createSelector(
//   getTapFeatureState,
//   state => state.taps
// );

export const getTapsAmounts = createSelector(
  getState,
  state => state.tapsAmounts
);





export const getItemWithKeg = (id: string) => createSelector(
  selectEntity(id),
  fromKegs.getKegsWithUnitBeverageAndLocation,
  (item: ITap | undefined, kegs: IKegVM[]) => {
    if (!!item && !!kegs) {


      const keg = kegs.find(x => x.id === item.kegId);

      return {
        ...item,
        keg: keg
      } as ITapVM;
    }

    return item as ITapVM;

  }
);




export const getItemWithKegAndBeverage = (id: string) => createSelector(
  selectEntity(id),
  fromKegs.getKegsWithUnitBeverageAndLocation,
  fromPlaato.selectAllEntities,
  fromKegtron.selectAllEntities,
  fromCustomKegMon.selectAllEntities,
  (item: ITap | undefined, kegs: any[], plaatos: Dictionary<IPlaato>, kegtrons: Dictionary<IKegtron>, customKegmons: Dictionary<ICustomKegMon>) => {
    if (item && kegs) {
      const retItem: any = {};
      if (!!item.kegId) {
        const keg = kegs.find(x => x.id === item.kegId);

        return {
          ...item,
          keg: keg,
          plaato: item.plaatoId ? plaatos[item.plaatoId] : undefined,
          kegtron: item.kegtronId ? kegtrons[item.kegtronId] : undefined,
          customKegMon: item.customKegMonId ? customKegmons[item.customKegMonId] : undefined,
        };

      }
    }

    return item;

  }
);


export const getItemsWithKegAndBeverage = createSelector(
  selectAllItems,
  fromKegs.getKegsWithUnitBeverageAndLocation,
  fromPlaato.selectAllEntities,
  fromPlaato2.selectAllEntities,
  fromKegtron.selectAllEntities,
  fromCustomKegMon.selectAllEntities,
  (taps: ITap[], kegs: IKeg[], plaatos: Dictionary<IPlaato>, plaato2s: Dictionary<IPlaato2>, kegtrons: Dictionary<IKegtron>, customKegmons: Dictionary<ICustomKegMon>) => {

    const items: ITapVM[] = [];

    taps.forEach((tap) => {
      const item: ITapVM = {
        ...tap,
        keg: kegs.find(x => x.id == tap.kegId),
        plaato: !!tap.plaatoId ? plaatos[tap.plaatoId] : undefined,
        plaato2: !!tap.plaato2Id ? plaato2s[tap.plaato2Id] : undefined,
        kegtron: !!tap.kegtronId ? kegtrons[tap.kegtronId] : undefined,
        customKegMon: !!tap.customKegMonId ? customKegmons[tap.customKegMonId] : undefined,
      };
      items.push(item);
    });
    return items;

  }
);







export const getItemsWithMonitors = createSelector(
  selectAllItems,
  fromPlaato.selectAllEntities,
  fromPlaato2.selectAllEntities,
  fromKegtron.selectAllEntities,
  fromCustomKegMon.selectAllEntities,
  (taps: ITap[], plaatos: Dictionary<IPlaato>, plaatos2: Dictionary<IPlaato2>, kegtrons: Dictionary<IKegtron>, customKegmons: Dictionary<ICustomKegMon>) => {

    const items: ITapVM[] = [];

    taps.forEach((tap) => {
      const item: ITapVM = {
        ...tap,
        plaato: !!tap.plaatoId ? plaatos[tap.plaatoId] : undefined,
        plaato2: !!tap.plaato2Id ? plaatos2[tap.plaato2Id] : undefined,
        kegtron: !!tap.kegtronId ? kegtrons[tap.kegtronId] : undefined,
        customKegMon: !!tap.customKegMonId ? customKegmons[tap.customKegMonId] : undefined,
      };
      items.push(item);
    });
    return items;

  }
);


export const getKegsWithTaps = createSelector(
  fromKegs.getKegsWithUnitBeverageAndLocation,
  getItemsWithMonitors,
  (kegs: IKegVM[], taps: ITap[]) => {
    return kegs.map((keg) => {
      return {
        ...keg,
        tap: taps.find(x => x.kegId === keg.id)
      }
    }) as IKegVM[];
  });

export const getKegWithTapsFromRouterParam = createSelector(
  fromRouter.selectRouteParams,
  fromKegs.getKegsWithUnitBeverageAndLocation,
  getItemsWithMonitors,
  ({ id }, kegs: IKegVM[], taps: ITap[]) => {

    const retKeg = kegs[id];
    return {
      ...retKeg,
      tap: taps.find(x => x.kegId === retKeg.id)
    } as IKeg;
  });

export const getKegsWithTapsFilteredByBeverageIdFromRouterParam = createSelector(
  fromRouter.selectRouteParams,
  getKegsWithTaps,
  ({ id }, kegs: IKegVM[]) => kegs.filter(keg => keg.beverageId === id));


export const getKegWithTaps = (id: string) => createSelector(
  fromKegs.getKegsWithUnitBeverageAndLocation,
  getItemsWithMonitors,
  (kegs: IKegVM[], taps: ITap[]) => {
    const keg = kegs.find(x => x.id === id);
    if (!!keg) {
      return {
        ...keg,
        tap: taps.find(x => x.kegId === keg.id)
      } as IKegVM;
    } else {
      return null;
    }

  });




export const getPlaatosWithTap = createSelector(
  fromPlaato.selectAllItems,
  getItemsWithKegAndBeverage,
  (items, taps) => {
    return items.map((item: IPlaatoVM) => {
      return {
        ...item,
        tap: taps.find(x => x.plaatoId === item.id)
      } as IPlaatoVM
    })
  });

export const getPlaato2sWithTap = createSelector(
  fromPlaato2.selectAllItems,
  getItemsWithKegAndBeverage,
  (items, taps) => {
    return items.map((item: IPlaato2VM) => {
      return {
        ...item,
        tap: taps.find(x => x.plaato2Id === item.id)
      } as IPlaato2VM
    })
  });


export const selectCurrentPlaato2WithTap = createSelector(
  fromPlaato2.selectCurrentItem,
  getPlaato2sWithTap,
  (item, plaatos) => {
    if (item && plaatos) {
      return {
        ...item,
        tap: plaatos.find(x => x.id === item.id)?.tap
      } as IPlaato2VM
    } else {
      return item;
    }
  });


export const getKegtronsWithTap = createSelector(
  fromKegtron.selectAllItems,
  getItemsWithKegAndBeverage,
  (items, taps) => {
    return items.map((item: IKegtronVM) => {
      return {
        ...item,
        tap: taps.find(x => x.kegtronId === item.id)
      } as IKegtronVM
    })
  });




export const getCustomKegMonsWithTap = createSelector(
  fromCustomKegMon.selectAllItems,
  getItemsWithKegAndBeverage,
  (items, taps) => {
    return items.map((item: ICustomKegMonVM) => {
      return {
        ...item,
        tap: taps.find(x => x.customKegMonId === item.id)
      } as ICustomKegMonVM
    })
  });




// export const getCurrentTap = createSelector(
//   getState,
//   state => state.currentTap
// );

