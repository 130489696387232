import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {


  constructor(public toastController: ToastController) { }



  async warning(warning: string, timer: number) {
    const toast = await this.toastController.create({
      message: warning,
      duration: timer,
      color: "warning"
    });
    toast.present();
  }
  async error(error: string) {
    const toast = await this.toastController.create({
      message: error,
      duration: 4000,
      color: "danger"
    });
    toast.present();
  }
  async errorWithTimer(error: string, timer: number) {
    const toast = await this.toastController.create({
      message: error,
      duration: timer,
      color: "danger"
    });
    toast.present();
  }
  async errorFromFirebaseWithTimer(error: any, timer: number) {

    const errorString: string = error.name + ": " + error.code;

    const toast = await this.toastController.create({
      message: errorString,
      duration: timer,
      color: "danger"
    });
    toast.present();
  }



  async saveSuccess() {
    const toast = await this.toastController.create({
      message: 'Your data have been saved.',
      duration: 100,
      color: "success"
    });
    toast.present();
  }

  async toastSuccess(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      color: "success"
    });
    toast.present();
  }

  async toastSuccessTop(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      position: "top",
      animated: true,
      color: "success"
    });
    toast.present();
  }

  async cheers() {
    const toast = await this.toastController.create({
      message: 'CHEERS!',
      duration: 200,
      color: "success",
      position: "top",
      animated: true


    });
    toast.present();
  }


  async cheersWithAmount(amount: string) {
    const toast = await this.toastController.create({
      message: 'CHEERS, ' + amount,
      duration: 1000,
      color: "success",
      position: "top",
      animated: true


    });
    toast.present();
  }

  async deleteItemSuccess() {
    const toast = await this.toastController.create({
      message: 'Your data has been deleted.',
      duration: 2000,
      color: "warning"
    });
    toast.present();
  }

}
